article {
  @apply mb-8;
}

article .header__title {
  @apply tracking-wide mb-3;
}

article .article__meta {
  @apply text-gray-600 mt-4;
}

article section > p:first-of-type {
  @apply mt-4;
}

section {
  @apply break-words;
}

pre.literal-block, pre.doctest-block, pre.math, pre.code,
section pre {
  @apply overflow-x-auto mb-8 border rounded p-3 mx-0;
}

section p {
  @apply mt-4;
}

section p + p,
section pre {
  @apply mt-8;
}

section code {
  @apply p-1 bg-gray-600 text-gray-200 rounded mb-px;
}

section a {
  @apply text-blue-600 ;
}

section a:visited {
  @apply text-purple-700;
}

section a:hover {
  @apply text-blue-800;
}


@variants hover {
  article .header__title__link {
    @apply bg-teal-400 p-1 text-black;
  }

  article .article__meta__link {
    @apply text-teal-600;
  }
}

article .article__meta__link {
  @apply text-indigo-600 font-bold;
}

mark {
  @apply pb-px pt-1 px-1;
}

article ol li {
  @apply ml-8 mt-4 list-decimal list-inside;
}

article ul li {
  @apply ml-8 mt-4 list-disc list-inside;
}

article ol li p,
article ul li p {
  @apply inline;
}


article hr {
  @apply mt-4;
}

article blockquote {
  @apply mt-4 bg-gray-300 px-5 pt-px pb-3 border-l-8 border-gray-600 text-gray-800;
}

article img {
  @apply mt-4;
}

/* display: block;
width: 100%;
overflow: auto; */

article table {
  @apply block mt-4 table-auto text-sm overflow-x-auto text-left w-full;
}

article table thead {
  @apply bg-gray-800 text-white;
}
article table th {
  @apply text-base font-semibold;
}
article table th,
article table td {
  @apply border-b border-gray-400 py-3 px-4 text-left;
}

