@tailwind base;

h1 {
  @apply text-5xl font-semibold;
}

h1:not(:first-child) {
  @apply mt-12;
}

h2 {
  @apply text-3xl font-semibold;
}

h2:not(:first-child) {
  @apply mt-10;
}

h3 {
  @apply text-2xl font-semibold;
}

h4:not(:first-child),
h3:not(:first-child) {
  @apply mt-8;
}

h4 {
  @apply text-xl font-semibold;
}

h5 {
  @apply text-lg mt-8 font-semibold;
}

h6 {
  @apply text-base mt-8 font-semibold;
}


@import './layout.css';

@import './article.css';

.thread__container {
  @apply text-default-color;
}

@tailwind components;

@tailwind utilities;
