:root {
  --primary-color: theme("colors.teal.500");
  /* --secondary-color: theme("colors.white"); */
  --default-color: theme("colors.black");
  --inverse-color: theme("colors.white")
}

#dark-mode:checked ~ .theme {
  --primary-color: theme("colors.teal.500");
  /* --secondary-color: theme("colors.white"); */
  --default-color: theme("colors.gray.300");
  --inverse-color: theme("colors.black")
}


/* label[for=dark-mode]::before {
  content: "🌚";
} */

.theme {
  @apply bg-inverse-color text-default-color;
}

.layout {
  @apply leading-normal flex flex-wrap w-full max-w-screen-xl mx-auto font-mono antialiased min-h-screen;
}

.cover {
  @apply w-full top-0 text-center mb-12 z-10;
}

.cover__title {
  @apply text-4xl mt-6;
}

.menu {
  @apply fixed bg-inverse-color h-full text-center w-full hidden pt-4 invisible opacity-0;
}

.menu ul li {
  @apply mb-4;
}

.menu ul li.active {
  @apply text-teal-500;
}

.menu__link {
  @apply border-gray-200;
}

.content {
  @apply w-full px-8 pt-4;
}

.pagination {
  @apply flex justify-between my-8 flex-wrap;
}

.tags {
  @apply flex justify-start flex-wrap;
}

.tags li,
.pagination li {
  @apply list-none m-0;
}

.tags li {
  @apply mr-3
}



@variants hover {
  .menu__link {
    @apply text-blue-600;
  }

  .pagination__link {
    @apply text-blue-600;
  }
  .dark-mode-label {
    @apply text-teal-500;
  }
}

.dark-mode-label {
  @apply align-bottom inline-block cursor-pointer fill-current text-gray-600
}

#dark-mode,
#cool-menu {
  @apply fixed transform scale-0 ;
}

#cool-menu + label > svg {
  @apply transition ease-in-out duration-500;
}

#cool-menu:checked + label > svg {
  @apply text-teal-500 transform rotate-180 transition ease-in-out duration-500;
}

#cool-menu:checked ~ .menu {
  @apply flex justify-center items-center visible opacity-100;
}

.cool-menu {
  @apply fill-current text-default-color;
}

.cool-menu__label {
  @apply left-1/2 fixed transform -translate-x-1/2  bottom-0 z-50 mb-4;
}

@screen lg {
  .menu {
    @apply w-1/4 border-r border-gray-200 border-b-0 px-8 text-right block relative visible opacity-100;
  }
  .content {
    @apply w-3/5;
  }

  .cool-menu {
    @apply hidden;
  }
}

@media (prefers-color-scheme: dark) {

}